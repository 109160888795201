/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CloudImageFrame {
  position: relative;
}
.CloudImageFrame .imageFrame {
  position: absolute;
  top: 7.5rem;
  left: 1.5rem;
  overflow: hidden;
  max-height: 30rem;
  border-radius: 2rem;
}
@media (max-width: 1199px) {
  .CloudImageFrame .imageFrame {
    top: 6rem;
    left: 1rem;
    width: 96%;
  }
}
.CloudImageFrame .imageFrame > .Image {
  max-width: 530px;
}
.CloudImageFrame .imageFrame > .Image > img {
  border-radius: 2rem;
}
.CloudImageFrame .imageFrame video {
  width: 100%;
  max-width: 530px;
  border-radius: 2rem;
}
.CloudImageFrame .imageFrame video:hover {
  cursor: pointer;
}
