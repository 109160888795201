/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.GenericLandingAboveFold {
  background: #2c65e6;
  padding-top: 7rem;
  padding-bottom: 16rem;
  position: relative;
}
@media (max-width: 767px) {
  .GenericLandingAboveFold {
    padding-top: 4rem;
    padding-bottom: 9rem;
  }
}
.GenericLandingAboveFold .right {
  width: 50%;
  display: inline-block;
  padding-right: 2rem;
}
@media (max-width: 767px) {
  .GenericLandingAboveFold .right {
    display: none;
  }
}
.GenericLandingAboveFold .left {
  padding-right: 20rem;
  padding-top: 3rem;
  padding-left: 2rem;
  width: 50%;
  display: inline-block;
}
@media (max-width: 1199px) {
  .GenericLandingAboveFold .left {
    padding-right: 8rem;
  }
}
@media (max-width: 767px) {
  .GenericLandingAboveFold .left {
    width: 100%;
    padding: 0;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.GenericLandingAboveFold .left .title {
  color: #fff;
  font-size: 4rem;
  font-weight: bold;
}
.GenericLandingAboveFold .left .title .firstLine {
  color: #ffdc4a;
}
.GenericLandingAboveFold .left .subtitle {
  color: #fff;
  font-size: 1.7rem;
  font-weight: 600;
  margin-top: 3rem;
}
.GenericLandingAboveFold .left .buttonWrapper {
  margin-top: 4rem;
  width: 100%;
  text-align: left;
}
@media (max-width: 767px) {
  .GenericLandingAboveFold .left .buttonWrapper {
    text-align: center;
  }
}
.GenericLandingAboveFold .trustpilotTopBox {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #191919;
  text-align: center;
  position: absolute;
  bottom: 6rem;
  width: 100%;
}
@media (max-width: 767px) {
  .GenericLandingAboveFold .trustpilotTopBox {
    bottom: 8rem;
    display: none;
  }
}
.GenericLandingAboveFold .trustpilotTopBox .scoreName {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  padding-right: 2rem;
  color: #fff;
}
.GenericLandingAboveFold .trustpilotTopBox .summary {
  margin-top: 1rem;
  margin-left: 2rem;
  display: inline-block;
  color: #fff;
}
.GenericLandingAboveFold .trustpilotTopBox .stars {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.GenericLandingAboveFold .trustpilotTopBox .logo {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.GenericLandingAboveFold .trustpilot-widget {
  margin-top: 4rem;
}
.GenericLandingAboveFold .Animation {
  width: 31rem;
  position: absolute;
  bottom: 0;
  right: 0;
}
.GenericLandingAboveFold .arrowAnimation {
  position: absolute;
  bottom: -2rem;
  z-index: 100;
  left: 50%;
  margin-left: -2.5rem;
  background: #ffdc4a;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  -webkit-animation: downarrow 1.75s infinite alternate ease-in-out;
  animation: downarrow 1.75s infinite alternate ease-in-out;
}
.GenericLandingAboveFold .arrowAnimation .arrow {
  border: solid white;
  border-width: 0 0.5rem 0.5rem 0;
  display: inline-block;
  padding: 0.7rem;
  margin-top: 1.3rem;
  margin-left: 1.3rem;
}
@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0) rotate(45deg);
    transform: translateY(0) rotate(45deg);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateY(3rem) rotate(45deg);
    transform: translateY(3rem) rotate(45deg);
    opacity: 1;
  }
}
