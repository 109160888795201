/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SocialReviews {
  position: relative;
  overflow: hidden;
  background: #f9f6ef;
  padding-left: 18rem;
  padding-right: 10rem;
  min-height: 65rem;
}
@media (max-width: 767px) {
  .SocialReviews {
    min-height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
.SocialReviews.noThumb > .maxWidthContentWrapper > .content {
  width: 100%;
}
@media (max-width: 767px) {
  .SocialReviews.noThumb > .maxWidthContentWrapper > .content {
    height: auto;
    overflow-x: auto;
  }
}
.SocialReviews.noThumb > .maxWidthContentWrapper > .content .trustpilotItem {
  width: 45%;
  margin-left: 5%;
  vertical-align: top;
  display: inline-block;
}
@media (max-width: 767px) {
  .SocialReviews.noThumb > .maxWidthContentWrapper > .content .trustpilotItem {
    width: 100%;
    margin-left: 0;
    display: none;
  }
  .SocialReviews.noThumb > .maxWidthContentWrapper > .content .trustpilotItem:nth-child(-n+4) {
    display: inline-block;
  }
}
.SocialReviews > .bottom {
  position: absolute;
  bottom: -3rem;
  left: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .SocialReviews > .bottom {
    display: none;
  }
}
.SocialReviews > .cloudRightTop {
  position: absolute;
  top: 15rem;
  right: -8rem;
}
@media (max-width: 767px) {
  .SocialReviews > .cloudRightTop {
    display: none;
  }
}
.SocialReviews > .cloudRightBottom {
  position: absolute;
  top: 35rem;
  right: 0;
}
@media (max-width: 767px) {
  .SocialReviews > .cloudRightBottom {
    display: none;
  }
}
.SocialReviews > .pipe {
  position: absolute;
  top: 0;
  right: 10rem;
}
@media (max-width: 767px) {
  .SocialReviews > .pipe {
    display: none;
  }
}
.SocialReviews > .maxWidthContentWrapper > .thumb {
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 50%;
}
@media (max-width: 991px) {
  .SocialReviews > .maxWidthContentWrapper > .thumb {
    display: none;
  }
}
.SocialReviews > .maxWidthContentWrapper > .content {
  width: 50%;
  margin-top: 4rem;
  height: 53rem;
  overflow-x: scroll;
  position: relative;
}
@media (max-width: 991px) {
  .SocialReviews > .maxWidthContentWrapper > .content {
    width: 100%;
    padding: 3rem;
  }
}
.SocialReviews > .maxWidthContentWrapper > .content > .title {
  margin-top: 10rem;
  margin-bottom: 4rem;
  font-size: 3.8rem;
  color: #745a9e;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 767px) {
  .SocialReviews > .maxWidthContentWrapper > .content > .title {
    font-size: 3rem;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
}
.SocialReviews > .maxWidthContentWrapper > .content > .description {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  color: #534468;
  font-weight: 600;
}
@media (max-width: 767px) {
  .SocialReviews > .maxWidthContentWrapper > .content > .description {
    font-size: 1.4rem;
  }
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotTopBox {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #191919;
  text-align: center;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotTopBox .scoreName {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  padding-right: 2rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotTopBox .summary {
  margin-top: 1rem;
  display: inline-block;
  color: #191919;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotTopBox .stars {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotTopBox .logo {
  height: 2rem;
  display: inline-block;
  margin-top: -0.4rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem {
  background: #f8f9fa;
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  border-bottom: 0.3rem solid #e1e7ed;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .top {
  color: #727272;
  position: relative;
  font-size: 1.2rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .top img {
  height: 2rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .top .author {
  display: inline-block;
  position: absolute;
  left: 12rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .top .bold {
  font-weight: bold;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .top .time {
  display: inline-block;
  position: absolute;
  right: 0;
  font-weight: normal;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .review {
  color: #191919;
  margin-top: 2rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .review .reviewTitle {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}
.SocialReviews > .maxWidthContentWrapper > .content .trustpilotItem .review .reviewCopy {
  font-size: 1.3rem;
}
