/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .HomePage {
    margin-top: -5rem;
  }
}
.HomePage .HowItWorks {
  background-color: #f9f6ef;
}
.HomePage .CourseReview {
  background: #fff;
}
.HomePage .importanceOfCode {
  background: url('/graphics/landing/why-code-important-background.svg') no-repeat #745a9e;
  background-size: cover;
  background-position: bottom;
  min-height: 55rem;
}
@media (max-width: 991px) {
  .HomePage .importanceOfCode {
    background-size: contain;
  }
}
.HomePage .importanceOfCode .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .HomePage .importanceOfCode .maxWidthContentWrapper {
    padding: 3rem;
  }
}
.HomePage .importanceOfCode .maxWidthContentWrapper > .title {
  color: #fff;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 75rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .HomePage .importanceOfCode .maxWidthContentWrapper > .title {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.HomePage .importanceOfCode .maxWidthContentWrapper > .subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 2.2rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 50rem;
  margin: 3rem auto 0;
}
@media (max-width: 767px) {
  .HomePage .importanceOfCode .maxWidthContentWrapper > .subtitle {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.HomePage .importanceOfCode .maxWidthContentWrapper .buttons {
  margin-top: 6rem;
  text-align: center;
}
.HomePage .whatCustomersSay {
  background: #f9f6ef;
}
.HomePage .whatCustomersSay > .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: bold;
  color: #745a9e;
  padding-top: 4rem;
}
.HomePage .whatCustomersSay .animationFrame {
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .HomePage .whatCustomersSay .animationFrame .desktop {
    display: none;
  }
}
.HomePage .whatCustomersSay .animationFrame .mobile {
  display: none;
}
@media (max-width: 767px) {
  .HomePage .whatCustomersSay .animationFrame .mobile {
    display: block;
  }
}
.HomePage .PaymentPlans {
  background: #f2eee5;
}
.HomePage .PaymentPlans .bottomInfo {
  background: #f9f6ef;
}
.HomePage .Introduction {
  position: relative;
  width: 100%;
  padding: 0 16rem;
  background: #f9f6ef;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .HomePage .Introduction {
    padding: 2rem 4rem;
  }
}
.HomePage .Introduction .topCloudImageFrame {
  position: absolute;
  top: 5rem;
  left: -13rem;
}
@media (max-width: 767px) {
  .HomePage .Introduction .topCloudImageFrame {
    display: none;
  }
}
.HomePage .Introduction .bottomCloudImageFrame {
  position: absolute;
  bottom: 5rem;
  right: -11rem;
}
@media (max-width: 767px) {
  .HomePage .Introduction .bottomCloudImageFrame {
    display: none;
  }
}
.HomePage .Introduction .row {
  margin: 11rem 0;
  z-index: 5;
  position: relative;
}
.HomePage .Introduction h2 {
  font-weight: 600;
  color: #745a9e;
  font-size: 5.2em;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .HomePage .Introduction h2 {
    text-align: center;
    font-size: 3.8em;
  }
}
.HomePage .Introduction .description {
  color: #534468;
  font-size: 2.2em;
  font-weight: 600;
}
@media (max-width: 767px) {
  .HomePage .Introduction .description {
    text-align: center;
    font-size: 1.6em;
  }
}
.HomePage .Introduction .textFrameLeft,
.HomePage .Introduction .textFrameRight {
  width: 40%;
  margin-top: -7rem;
}
@media (max-width: 767px) {
  .HomePage .Introduction .textFrameLeft,
  .HomePage .Introduction .textFrameRight {
    width: 100%;
    float: none;
    margin-top: 3rem;
  }
}
.HomePage .Introduction .imageFrameLeft,
.HomePage .Introduction .imageFrameRight {
  width: 40%;
}
.HomePage .Introduction .imageFrameLeft img,
.HomePage .Introduction .imageFrameRight img {
  border-radius: 3rem;
}
@media (max-width: 767px) {
  .HomePage .Introduction .imageFrameLeft,
  .HomePage .Introduction .imageFrameRight {
    width: 100%;
    float: none;
  }
}
.HomePage .Introduction .textFrameLeft {
  float: left;
}
.HomePage .Introduction .textFrameRight {
  float: right;
}
.HomePage .Introduction .imageFrameLeft {
  float: left;
}
.HomePage .Introduction .imageFrameRight {
  float: right;
}
.HomePage .greyCarouselBackground {
  background: #f9f6ef;
}
