/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CampReviewsCarousel,
.SocialCarousel {
  position: relative;
  text-align: center;
  max-width: 35rem;
}
@media (max-width: 767px) {
  .CampReviewsCarousel,
  .SocialCarousel {
    max-width: none;
  }
}
.SocialCarousel__container {
  position: relative;
  overflow: hidden;
  padding-top: 100%;
}
.SocialCarousel__button {
  position: absolute;
  top: 12rem;
  cursor: pointer;
}
@media (max-width: 767px) {
  .SocialCarousel__button {
    position: relative;
    display: inline-block;
    padding-left: 3rem;
    top: 0;
    margin: auto;
    padding-right: 4rem;
    padding-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .SocialCarousel__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
  }
}
.SocialCarousel__button--left {
  left: -9rem;
}
.SocialCarousel__button--right {
  right: -9rem;
}
