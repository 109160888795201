/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EmailBanner {
  width: 100%;
  padding-left: 10rem;
  padding-right: 10rem;
  position: relative;
  transition: height 0.4s;
  height: 34rem;
}
@media (max-width: 991px) {
  .EmailBanner {
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
}
.EmailBanner--collapsed {
  height: 0;
}
.EmailBanner__content {
  display: table;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .EmailBanner__content {
    margin: auto;
    padding-bottom: 5rem;
    position: relative;
    top: 0;
    transform: none;
  }
}
.EmailBanner__content--collapsed {
  display: none;
}
.EmailBanner__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
@media (max-width: 991px) {
  .EmailBanner__close {
    display: none;
  }
}
.EmailBanner__cell {
  display: table-cell;
  width: 20%;
  vertical-align: middle;
  border: 0 transparent solid;
  border-width: 0 2em;
  background-clip: padding-box;
}
@media (max-width: 991px) {
  .EmailBanner__cell {
    width: 100%;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 5rem;
  }
}
@media (max-width: 991px) {
  .EmailBanner__cell:last-child {
    margin-bottom: 0;
  }
}
.EmailBanner__title {
  color: #fff;
  font-weight: 600;
  font-size: 3.5rem;
  padding-bottom: 2rem;
}
.EmailBanner__subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}
.EmailBanner__sent {
  margin: auto;
  text-align: center;
  position: relative;
  top: 50%;
}
.EmailBanner__shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
/********
 KILL ME
*********/
.EmailBanner__cell > div > .EmailInput > div > .Input.valid > label {
  border-radius: 0.4em;
  outline: none;
  margin-bottom: 1.3rem;
  margin-bottom: 0;
}
.EmailBanner__cell > div > .EmailInput > div > .Input.valid > label > input {
  width: 100%;
}
