/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ProductOverview {
  background: #b6e7ff;
}
.ProductOverview .bottom {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ProductOverview .title {
  color: #095d84;
  font-size: 3.4rem;
  font-weight: 600;
  margin-top: 8rem;
  padding-right: 3rem;
}
@media (max-width: 767px) {
  .ProductOverview .title {
    font-size: 2.6rem;
    margin-top: 2rem;
  }
}
.ProductOverview .supportVideo,
.ProductOverview .productPreview,
.ProductOverview .minecraft,
.ProductOverview .roblox {
  margin: auto 0;
  padding-bottom: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 3rem;
  position: relative;
  overflow: hidden;
}
.ProductOverview .supportVideo.roblox,
.ProductOverview .productPreview.roblox,
.ProductOverview .minecraft.roblox,
.ProductOverview .roblox.roblox {
  padding-top: 0;
}
@media (max-width: 767px) {
  .ProductOverview .supportVideo,
  .ProductOverview .productPreview,
  .ProductOverview .minecraft,
  .ProductOverview .roblox {
    padding: 1.5rem;
  }
  .ProductOverview .supportVideo img,
  .ProductOverview .productPreview img,
  .ProductOverview .minecraft img,
  .ProductOverview .roblox img {
    visibility: hidden;
  }
}
.ProductOverview .supportVideo .description,
.ProductOverview .productPreview .description,
.ProductOverview .minecraft .description,
.ProductOverview .roblox .description {
  color: #095d84;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 3rem;
  padding-right: 10rem;
}
@media (max-width: 767px) {
  .ProductOverview .supportVideo .description,
  .ProductOverview .productPreview .description,
  .ProductOverview .minecraft .description,
  .ProductOverview .roblox .description {
    margin-bottom: 0;
    padding-right: 0;
  }
}
.ProductOverview .supportVideo .RoundButton,
.ProductOverview .productPreview .RoundButton,
.ProductOverview .minecraft .RoundButton,
.ProductOverview .roblox .RoundButton {
  margin-top: 3rem;
  display: block;
}
.ProductOverview .supportVideo .mobile,
.ProductOverview .productPreview .mobile,
.ProductOverview .minecraft .mobile,
.ProductOverview .roblox .mobile {
  display: none;
}
@media (max-width: 767px) {
  .ProductOverview .supportVideo .mobile,
  .ProductOverview .productPreview .mobile,
  .ProductOverview .minecraft .mobile,
  .ProductOverview .roblox .mobile {
    display: block;
  }
}
.ProductOverview .supportVideo .notMobile,
.ProductOverview .productPreview .notMobile,
.ProductOverview .minecraft .notMobile,
.ProductOverview .roblox .notMobile {
  display: block;
}
@media (max-width: 767px) {
  .ProductOverview .supportVideo .notMobile,
  .ProductOverview .productPreview .notMobile,
  .ProductOverview .minecraft .notMobile,
  .ProductOverview .roblox .notMobile {
    display: none;
  }
}
