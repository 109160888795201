/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.WhatsIncludedCarousel {
  position: relative;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 8rem;
  background-color: white;
}
@media (max-width: 991px) {
  .WhatsIncludedCarousel {
    padding-bottom: 0;
  }
}
.WhatsIncludedCarousel__title {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 2rem;
  color: #095d84;
}
@media (max-width: 991px) {
  .WhatsIncludedCarousel__title {
    padding-top: 0;
  }
}
.WhatsIncludedCarousel__flex {
  display: flex;
  text-align: center;
  max-width: 136rem;
  margin: auto;
}
@media (max-width: 991px) {
  .WhatsIncludedCarousel__flex {
    display: block;
    margin: auto;
  }
}
.WhatsIncludedSlide {
  flex: 1;
  position: relative;
  max-width: 53rem;
  margin: auto;
  padding-left: 5rem;
  padding-right: 5rem;
}
@media (max-width: 991px) {
  .WhatsIncludedSlide {
    padding-bottom: 5rem;
  }
}
.WhatsIncludedSlide__content {
  font-size: 1.8rem;
  font-weight: 600;
  color: #402e59;
  overflow: hidden;
  margin-top: 2rem;
}
.WhatsIncludedSlide__title {
  font-weight: 600;
  font-size: 2.2rem;
  color: #095d84;
}
.WhatsIncludedSlide__text {
  font-size: 1.4rem;
  color: #402e59;
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 1rem;
  font-weight: 600;
}
.WhatsIncludedSlide__image {
  width: 100%;
}
.WhatsIncludedSlide__image-src {
  border-radius: 0.9rem;
}
.WhatsIncludedSlide__cloudLeft {
  left: -11rem;
  top: 11rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .WhatsIncludedSlide__cloudLeft {
    display: none;
  }
}
.WhatsIncludedSlide__cloudRight {
  right: -8rem;
  top: 24rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .WhatsIncludedSlide__cloudRight {
    display: none;
  }
}
